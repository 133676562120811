/* eslint-disable no-multi-str */
import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art1_image from "../../../static/img/art1.jpeg";
import art3_image from "../../../static/img/art3.jpg";
import art4_image from "../../../static/img/art4.jpg";
import art5_image from "../../../static/img/art5.jpg";
import art6_image from "../../../static/img/art6.jpg";
import art7_image from "../../../static/img/art7.jpeg";
import art8_image from "../../../static/img/art8.png";
import art9_image from "../../../static/img/art9.png";
import art10_image from "../../../static/img/art10.png";
import art11_image from "../../../static/img/art11.png";
import art12_image from "../../../static/img/art12.jpg";
import art13_image from "../../../static/img/art13.png";
import art14_image from "../../../static/img/art14.png";
import art15_image from "../../../static/img/art15.jpg";
import art16_image from "../../../static/img/art16.jpg";
import art17_image from "../../../static/img/art17.png";
import art18_image from "../../../static/img/art18.jpg";
import art19_image from "../../../static/img/art19.png";
import art20_image from "../../../static/img/art20.jpg";
import art21_image from "../../../static/img/art21.jpg";
import art22_image from "../../../static/img/art22.png";
import art23_image from "../../../static/img/art23.png";
import art24_image from "../../../static/img/art24.jpg";
import art25_image from "../../../static/img/art25_1.jpg";
import art26_image from "../../../static/img/art26.png";
import consortium_image from "../../../static/img/consortium.png";

import { sortBy } from 'lodash';

let blogs = [
  {
    'id': 1,
    'nav': '/blog1',
    'img': consortium_image,
    'title': 'Integrated Care Platform Based on the Monitoring of \
              Older Individual Intrinsic Capacity for Inclusive Health (CAREUP)',
    'published': '28 September 2022',
    'text': 'The CAREUP project investigates in the implementation of the inclusive \
            health & care approach ...'
  },
  {
    'id': 2,
    'nav': '/blog2',
    'img': art1_image,
    'title': 'Usage of technical solutions in daily life – how to deal with \
              privacy, confidentiality and data protection',
    'published': '03 November 2022',
    'text': 'In view of demographic change and the competition for \
                  employees in the care sector ...'
  },
  {
    'id': 3,
    'nav': '/blog3',
    'img': art3_image,
    'title': 'Positive Health, Intrinsic Capacity, and CAREUP system',
    'published': '21 December 2022',
    'text': 'Population ageing is a global trend. Older people who are \
             facing this stage of life, especially those with physical and \
             cognitive problems related to ...'
  },
  {
    'id': 4,
    'nav': '/blog4',
    'img': art4_image,
    'title': 'Benefits of Digital Games for Seniors',
    'published': '17 January 2023',
    'text': 'It is an acknowledged fact that there are several barriers \
                      when it comes to seniors and ...'
  }, 
  {
    'id': 5,
    'nav': '/blog5',
    'img': art5_image,
    'title': 'CAREUP as a tool for elderly people assistance in the frame of \
              revised EU pandemic plans',
    'published': '25 February 2023',
    'text': 'It is an acknowledged fact that there are several barriers \
                      when it comes to seniors and ...'
  },
  {
    'id': 6,
    'nav': '/blog6',
    'img': art6_image,
    'title': 'Serious games for seniors',
    'published': '25 April 2023',
    'text': 'One of the biggest challenges we face today is that, in the \
                  European Union alone, ...'
  },
  {
    'id': 7,
    'nav': '/blog7',
    'img': art7_image,
    'title': 'Healthcare and AI',
    'published': '25 July 2023',
    'text': 'The emergence of artificial intelligence (AI) in healthcare \
                  has been ground-breaking ...'
  },
  {
    'id': 8,
    'nav': '/blog8',
    'img': art8_image,
    'title': 'CAREUP in the landscape of the AgTech market worldwide',
    'published': '15 August 2023',
    'text': '"AgTech" is a label that encompasses technological offerings \
                  tailored to older adults ...'
  },
  {
    'id': 9,
    'nav': '/blog9',
    'img': art9_image,
    'title': 'Challenges for Assistive Technologies That Target Older \
                  Adults: Using Examples from AAL Funded Projects and the wider literature',
    'published': '20 September 2023',
    'text': 'Dementia cases are projected to increase to 152 million by 2050 ...'
  },
  {
    'id': 10,
    'nav': '/blog10',
    'img': art10_image,
    'title': 'How to increase Intrinsic Capacity?',
    'published': '31 October 2023',
    'text': 'Intrinsic capacity refers to the physical and mental \
                  attributes that individuals possess that enable them to perform daily ...'
  },
  {
    'id': 11,
    'nav': '/blog11',
    'img': art11_image,
    'title': 'eHealth platforms for the independent living and active ageing',
    'published': '30 November 2023',
    'text': 'In an era where populations are aging rapidly, and chronic diseases are on the rise, \
                the demand for innovative solutions ...'
  },
  {
    'id': 12,
    'nav': '/blog13',
    'img': art13_image,
    'title': 'Safeguarding Healthcare: Navigating the Security Landscape of AI',
    'published': '22 December 2023',
    'text': 'In the modern healthcare landscape, the integration of artificial intelligence (AI) has heralded a new era of efficiency, \
                  accuracy, and innovation ... '
  },
  {
    'id': 13,
    'nav': '/blog12',
    'img': art12_image,
    'title': 'Overcoming Challenges in Healthcare Digitalization for the Elderly',
    'published': '31 January 2024',
    'text': 'The digital revolution has transformed and enhanced many aspects of our lives ...'
  },
  {
    'id': 14,
    'nav': '/blog14',
    'img': art14_image,
    'title': 'Home health monitoring technologies for older adults: user acceptance, issues and challenges',
    'published': '29 Febuary 2024',
    'text': 'Smart home and health monitoring technologies are increasingly emerging as the future for managing  \
                chronic diseases and allowing people to age ...'
  },
  {
    'id': 15,
    'nav': '/blog15',
    'img': art15_image,
    'title': 'The Impact of Cybersecurity on Health Systems: Challenges and Solutions',
    'published': '15 April 2024',
    'text': 'In an age where digitization is revolutionizing every aspect of our lives, the healthcare sector is not exempt from the transformative \
                  power of technology. Electronic health records (EHRs), telemedicine ...'
  },
  {
    'id': 16,
    'nav': '/blog16',
    'img': art16_image,
    'title': 'Informal carers and the privacy regulations: how to deal with data management and data access?',
    'published': '17 April 2024',
    'text': 'Informal carers, i.e. family members or friends, play a vital role in providing assistance and support to individuals \
                  with chronic illnesses, disabilities, or age-related conditions. ...'
  },
  {
    'id': 17,
    'nav': '/blog17',
    'img': art17_image,
    'title': 'Data Exchange Advances and Standards in Computer and Information Systems Revolutionizing Healthcare',
    'published': '18 April 2024',
    'text': 'Effective data exchange is paramount for improving patient care, enhancing clinical decision-making, and advancing medical research. ...'
  },
  {
    'id': 18,
    'nav': '/blog18',
    'img': art18_image,
    'title': 'Dependent living and caregiver burden',
    'published': '19 April 2024',
    'text': 'The average life expectancy at birth has, and will continue to, increase in the modern era. According to the United Nations, \
                the population over the age of 65 in Europe has more than tripled since ...'
  },
  {
    'id': 19,
    'nav': '/blog19',
    'img': art19_image,
    'title': 'Gait analysis – what, why and how?',
    'published': '13 May 2024',
    'text': 'As nowadays we live in the quickly ageing society, a need for the novel methods of \
                  the quick health and wellbeing assessment of the older adults is emerging. ...'
  },
  {
    'id': 20,
    'nav': '/blog20',
    'img': art20_image,
    'title': 'Creating Better Technology for Older Adults: How User-Focused Design Can Make a Difference',
    'published': '17 June 2024',
    'text': 'The global demographic shift towards an aging population presents a significant \
                  challenge and opportunity for technology developers to create solutions that cater \
                  to the unique needs of older adults. ...'
  },
  {
    'id': 21,
    'nav': '/blog21',
    'img': art21_image,
    'title': 'Intrinsic Capacity and Positive Health: Concepts and Implications. The CAREUP case',
    'published': '16 July 2024',
    'text': 'The concepts of Intrinsic Capacity and Positive Health are central to modern approaches in health and well-being, \
                  especially in the context of aging populations and preventive healthcare. ...'
  },
  {
    'id': 22,
    'nav': '/blog22',
    'img': art22_image,
    'title': 'How to promote the adoption and sustainability of Health tecnologies for elderly people',
    'published': '19 August 2024',
    'text': 'Promoting the adoption and sustainability of health technologies for elderly people involves \
                    addressing unique challenges and leveraging opportunities to enhance their well-being. ...'
  },
  {
    'id': 23,
    'nav': '/blog23',
    'img': art23_image,
    'title': 'Frontiers 2024 in Monitoring Older Adults for Inclusive Health and Care',
    'published': '16 September 2024',
    'text': 'The world is facing a dramatic demographic transition, with populations aging at unprecedented rates. \
                    According to the World Health Organization (WHO), the global population of persons aged 60 and older is \
                    predicted to more than double by 2050, reaching 2.1 billion. ...'
  },
  {
    'id': 24,
    'nav': '/blog24',
    'img': art24_image,
    'title': 'Launch of the CAREUP Pilot Study: Testing Phase Begins After Extended Development',
    'published': '21 October 2024',
    'text': 'Following an intensive phase of planning and development, the CAREUP pilot study has \
                    now officially commenced. ...'
  },
  {
    'id': 25,
    'nav': '/blog25',
    'img': art25_image,
    'title': 'The importance of physical activity in the older population',
    'published': '6 November 2024',
    'text': 'The older population is increasing worldwide, and it is estimated that it may even double by 2060 (US Dept of Commerce, 2014). ...'
  },
  {
    'id': 26,
    'nav': '/blog26',
    'img': art26_image,
    'title': 'Cybersecurity in Healthcare: Protecting Data to Ensure Patient Confidence',
    'published': '18 December 2024',
    'text': 'Digitization is profoundly transforming the healthcare sector. The introduction of electronic medical record management systems, \
              connected medical devices and telemedicine platforms is improving ...'
  }

];


let len = blogs.length;
let r = len % 3;
let n = len - r;

const Blogs = () => {
  return (
    <div className="container ">
      <div className="row" id="main-title">
        <h1>Blog posts</h1>
      </div>
      {
        sortBy(blogs, ['id']).map((el, i) => {
          let k = len - i + 1;   // to show in reverse order       
          let j = i + 1;                   
          if (j <= n) {
            if (j % 3 === 0) {
              return (
                <div className="row  row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
                  <NavLink to={blogs[k].nav}>
                    <div className="col">
                      <div className="card">
                        <img src={blogs[k].img} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">
                            {blogs[k].title}
                          </h5>
                          <p className="card-text">
                            <small className="text-muted">
                              Published on {blogs[k].published}
                            </small>
                          </p>
                          <p className="card-text">
                            {blogs[k].text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to={blogs[k - 1].nav}>
                    <div className="col">
                      <div className="card">
                        <img src={blogs[k - 1].img} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">
                            {blogs[k - 1].title}
                          </h5>
                          <p className="card-text">
                            <small className="text-muted">
                              Published on {blogs[k - 1].published}
                            </small>
                          </p>
                          <p className="card-text">
                            {blogs[k - 1].text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to={blogs[k -2].nav}>
                    <div className="col">
                      <div className="card">
                        <img src={blogs[k - 2].img} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">
                            {blogs[k - 2].title}
                          </h5>
                          <p className="card-text">
                            <small className="text-muted">
                              Published on {blogs[k - 2].published}
                            </small>
                          </p>
                          <p className="card-text">
                            {blogs[k - 2].text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              )
            }
          }
          else {
            if (r === 2 && j === len) {
              return (
                <div className="row  row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
                  <NavLink to={blogs[k - 1].nav}>
                    <div className="col">
                      <div className="card">
                        <img src={blogs[k - 1].img} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">
                            {blogs[k - 1].title}
                          </h5>
                          <p className="card-text">
                            <small className="text-muted">
                              Published on {blogs[k - 1].published}
                            </small>
                          </p>
                          <p className="card-text">
                            {blogs[k - 1].text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <NavLink to={blogs[k - 2].nav}>
                    <div className="col">
                      <div className="card">
                        <img src={blogs[k - 2].img} className="card-img-top" alt="..." />
                        <div className="card-body">
                          <h5 className="card-title">
                            {blogs[k - 2].title}
                          </h5>
                          <p className="card-text">
                            <small className="text-muted">
                              Published on {blogs[k - 2].published}
                            </small>
                          </p>
                          <p className="card-text">
                            {blogs[k - 2].text}
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <div className="col"></div>
                </div>
              )
            }          
             else
              if (r === 1 && j === len) {
                return (
                  <div className="row  row-cols-1 row-cols-sm-1 row-cols-md-3 blogs">
                    <NavLink to={blogs[k - 2].nav}>
                      <div className="col">
                        <div className="card">
                          <img src={blogs[k - 2].img} className="card-img-top" alt="..." />
                          <div className="card-body">
                            <h5 className="card-title">
                              {blogs[k - 2].title}
                            </h5>
                            <p className="card-text">
                              <small className="text-muted">
                                Published on {blogs[k - 2].published}
                              </small>
                            </p>
                            <p className="card-text">
                              {blogs[k - 2].text}
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                    <div className="col"></div>
                    <div className="col"></div>
                  </div>
                )
              }
            }
        })}

    </div>

  );
};

export default Blogs;
