import { NavLink } from "react-router-dom";
import "./Blogs.css";
import art24_image from "../../../static/img/art24.jpg";

const Blog24 = () => {
  return (    
      <div className="container ">
        <div className="row blogs" id="main-title">
          <NavLink to="/blogs">
            <h1>Blogs</h1>
          </NavLink>
        </div>
        <div className="row full_blogs">
          <h4>
            Launch of the CAREUP Pilot Study: Testing Phase Begins After Extended Development
          </h4>
          <p class="card-text">
            <small className="text-muted">Published on 21 October 2024</small>
          </p>
          <img src={art24_image} className="agtech2" alt="..." />            
          <p>
          Following an intensive phase of planning and development, the CAREUP pilot study has now 
          officially commenced. The aim of this study is to test the innovative CAREUP system 
          in real-world conditions and gather valuable insights regarding its effectiveness 
          and user-friendliness.<br /><br />
          At the heart of the system is the preservation of intrinsic capacity—a concept 
          that refers to the ability to actively and independently manage one’s life despite 
          age-related limitations. A unique feature of the study is that participants were given 
          the opportunity to choose from a selection of devices which ones they would like to test. 
          These devices are designed to support both their physical and mental health.
          </p>
          <p></p>
          <p>
            <b>Health Status as a Starting Point</b> <br />             
          </p>                    
          <p>
            Before participants began using the CAREUP system, their current health status was 
            thoroughly assessed. This was done through questionnaires that covered both physical 
            and mental health aspects. These data serve as an important foundation for the system, 
            which generates personalized daily tasks based on the individual’s information. 
            These tasks are designed to help maintain or even improve the user’s intrinsic capacity.<br /><br />
            In addition to the initial questionnaires, the CAREUP system continuously receives new data 
            from participants. Throughout the study period, users will periodically answer questions about 
            their health and well-being. This information flows directly into the system, allowing the tasks 
            to be adjusted regularly to better fit the individual’s needs.
          </p>
          <p></p>
          <p>
            <b>User-Centered Concept</b> <br />             
          </p>                    
          <p>
            A key aspect of the CAREUP pilot study is the flexibility given to participants. 
            How often they enter new data or complete tasks is largely up to them. The system is 
            designed to adapt to the users' needs and daily routines without being overwhelming. 
            The goal is to respect individual preferences while providing gentle support for daily 
            health maintenance.<br /><br />
            Participants have understood the concept of intrinsic capacity and are aware that regular 
            interaction with the system plays a central role in preserving their physical and mental 
            abilities in the long term. The upcoming testing phase, which will run for a predefined period, 
            will reveal how well the system is accepted in daily life and whether any adjustments are needed 
            to further enhance the user experience.<br /><br />
            The CAREUP pilot study is an important step in integrating modern technologies into healthcare 
            and supporting people in living actively and independently for as long as possible. 
            The results of the study will not only contribute to the further development of the system 
            but also provide valuable insights into how digital solutions can help strengthen individual health.
          </p>
          <p></p>

        </div>           
        
        <script>window.scrollTo(0,0);</script>
      </div>
            
  );
};

export default Blog24;
